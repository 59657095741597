import React, { useContext } from 'react'

import { BodyText2SemiBold } from '@lumoslabs/lumosity-storybook'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import styled from 'styled-components'

import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { HeaderTypes } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { GameContext } from '~/context/GameContext'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { getTotalGamesCount, getTotalGamesPlayed } from '~/utils/workoutUtils'

export interface WorkoutHeaderProps {
  headerType: HeaderTypes
  workoutData: GetTodaysWorkoutQuery
}

const WorkoutHeader: React.FC<WorkoutHeaderProps> = ({ headerType, workoutData }) => {
  const t = useTranslationForNamespace('common')
  const { workoutSlotKey } = useContext(GameContext)
  const donutAnimation = useSpring({
    from: { transform: 'translate3d(250px,0,0) rotateZ(120deg)' },
    to: { transform: 'translate3d(0px,0,0) rotateZ(0deg)' },
  })

  const { hasPremium } = useContext(AuthContext)

  const totalGameCount = getTotalGamesCount(hasPremium)

  const currentSlot = workoutData.todaysWorkout.slots[workoutSlotKey]
  const totalGamesPlayed = getTotalGamesPlayed(workoutData)
  let currentGamePosition = Math.min(totalGamesPlayed + 1, totalGameCount)

  //game is being replayed
  if (currentSlot?.lastGamePlay && totalGamesPlayed != totalGameCount) currentGamePosition--

  const gameText = t('workoutHeader.workoutCount', {
    currentGamePosition,
    totalGameCount,
  })

  return (
    <WorkoutContainer>
      <WorkoutHeaderCount>{gameText}</WorkoutHeaderCount>
      <animated.div style={{ ...donutAnimation, width: '56px', height: '56px' }}>
        <WorkoutDonut workout={workoutData} headerType={headerType} />
      </animated.div>
    </WorkoutContainer>
  )
}

const WorkoutHeaderCount = styled(BodyText2SemiBold)`
  display: flex;
  color: ${({ theme }) => theme.colors.neutral[400]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 200px;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
`

const WorkoutContainer = styled.div`
  padding-right: 6vw;
  display: flex;
  align-items: center;
  z-index: 100;
  position: relative;
  gap: 28px;
  svg {
    width: 56px;
    filter: drop-shadow(0px 2px 100px ${({ theme }) => theme.colors.whiteBase});
  }
`

export default WorkoutHeader
